<template>
    <div class="profile profile--single" v-if="profil">
        <span @click="$router.go(-1);" v-if="$route.name === 'ProfilSingle'" class="backlink"><inline-svg :src="require('@/assets/images/ic_close.svg')"/></span>
        <img :src="profil.image" class="profile-image" width="48" v-if="profil.image">
        <span v-else class="profile-image  profile-image--placeholder">{{ initials }}</span>
        <h2 class="profile-fullname"><span v-text="profil.firstName + ' ' + profil.lastName"></span></h2>

        <div class="profile-data">
            <h4>Dienstlich</h4>

            <p v-if="profil.division">
                <span>{{$t('profile_division')}}</span>
                <span v-html="profil.division"></span>
            </p>

            <p v-if="profil.workPosition">
                <span>{{$t('profile_work_position')}}</span>
                <span v-html="profil.workPosition"></span>
            </p>
            <p v-if="profil.workScope">
                <span>{{$t('profile_work_scope')}}</span>
                <span v-html="profil.workScope"></span>
            </p>

            <p v-if="profil.telephone">
            <span>{{$t('profile_telephone')}}</span>
            <a :href="`tel:${profil.telephone}`">{{ profil.telephone }}</a>
            </p>
            <p v-if="profil.fax">
            <span>{{$t('profile_fax')}}</span>
            <span>{{ profil.fax }}</span>
            </p>
            <p v-if="profil.email">
            <span>{{$t('profile_email')}}</span>
            <a :href="`mailto:${profil.email}`">{{ profil.email }}</a>
            </p>
        </div>

        <div class="profile-data" v-show="privateBlock">
            <h4>Persönliche Angaben</h4>
            <p v-if="profil.birthday">
                <span>{{$t('profile_birthday')}}</span>
                <span>{{ $filters.formatDate(profil.birthday, 'j. F') }}</span>
            </p>

            <p v-if="profil.telephone_privat">
                <span>{{$t('profile_telephone_privat')}}</span>
                <span>{{ profil.telephone_privat }}</span>
            </p>

            <p v-if="profil.telephone_mobile2">
                <span>{{$t('profile_telephone_mobile2')}}</span>
                <span>{{ profil.telephone_mobile2 }}</span>
            </p>

            <p v-if="profil.emailPrivat">
                <span>{{$t('profile_email_privat')}}</span>
                <span>{{ profil.emailPrivat }}</span>
            </p>

            <p v-if="profil.www">
                <span>{{$t('profile_www')}}</span>
                <a :href="`https://${profil.www}`" target="_blank" rel="nofollow">{{ profil.www }}</a>
            </p>

            <p v-if="formatedAddress">
                <span>Wohnort</span>
                <span>{{ formatedAddress }}</span>
            </p>

            <p v-if="profil.expertise">
                <span>{{$t('profile_expertise')}}</span>
                <span v-html="profil.expertise"></span>
            </p>

            <p v-if="profil.devise">
                <span>{{$t('profile_devise')}}</span>
                <span v-html="profil.devise"></span>
            </p>

            <p v-if="profil.hobby">
                <span>{{$t('profile_hobby')}}</span>
                <span v-html="profil.hobby"></span>
            </p>

        </div>
    </div>
</template>

<script>
import apiCall, { ApiDefaults } from '../../utils/api_call';

export default {
	name: "ProfilSingle",
	data() {
		return {
			profil: false
		};
	},
	props: {
		contact: {
			type: Object
        }
    },
	computed: {
		privateBlock: function(){
			let show = false;
			['telephone2','birthday','telephoneMobile2','emailPrivat','city','expertise','devise','hobby'].forEach((key)=>{
				switch( key ) {
                    case 'birthday':
						if(  this.profil[key] !== '' ) show = true;
                    break;
                    default:

						if( this.profil[key] !== '' && typeof this.profil[key] !== 'undefined' ) show = true;
                }

            });
			return show;
        },
		initials: function () {
			return this.profil.firstName.substr(0, 1) + this.profil.lastName.substr(0, 1);
		},
		formatedAddress: function(){
			return this.profil.city;
        }
	},
	methods: {
		hasHistory: function() { return window.history.length > 2 },
		fetchProfil: function () {
			if( this.contact ) {
				this.profil = this.contact;
				return;
            }
			let userId = this.$route.params.id,
				apiSettings = Object.assign({}, ApiDefaults, {
					params: {
						id: 7,
						'tx_c3json_jsondataplugin[controller]': 'JsonDataUser',
						'tx_c3json_jsondataplugin[action]': 'profile',
						'tx_c3json_jsondataplugin[user]': userId

					}
				});

			apiCall(apiSettings)
				.then(( response ) => {
					if( Object.hasOwnProperty.call(response.data,'user')){
						this.profil = response.data.user;
                    }
				})
				.catch(() => {
				});
		}
	},
    watch : {
		contact: function(val) {
			this.profil = val;
        }
    },
	created() {
		this.fetchProfil();
	}
};
</script>

<style scoped>

</style>